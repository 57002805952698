(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

require('./modules/spoilers');

require('./modules/menus');

require('./modules/modals');

require('./modules/forms');

require('./modules/scroll');

require('./modules/sliders');

require('./modules/tabs');

require('./modules/masks');

require('./modules/main');

require('./modules/plugins');

},{"./modules/forms":2,"./modules/main":5,"./modules/masks":6,"./modules/menus":7,"./modules/modals":8,"./modules/plugins":9,"./modules/scroll":10,"./modules/sliders":11,"./modules/spoilers":12,"./modules/tabs":13}],2:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

// Selectric
// ---------

_globals.$DOCUMENT.on('initSelectric ready yiiListViewUpdated', function () {
  $('select:not(.no-default-selectric)').selectric({
    disableOnMobile: false,
    nativeOnMobile: true,
    onOpen: function onOpen() {
      // Против бага на Сафари 16.1
      var wrp = this.closest('.selectric-wrapper');
      var fixer = wrp.getElementsByClassName('selectric-items')[0];
      fixer.style.display = 'none';
      fixer.offsetHeight; // no need to store this anywhere, the reference is enough
      fixer.style.display = 'block';
    },
    onClose: function onClose() {
      // Против бага на Сафари 16.1
      var wrp = this.closest('.selectric-wrapper');
      var fixer = wrp.getElementsByClassName('selectric-items')[0];
      fixer.style.display = 'none';
    }
  });
}).trigger('initSelectric');

// Проверка селектов вариантов в карточке. Если вариант всего 1 - делаем серым цветом.

window.addEventListener('DOMContentLoaded', function () {
  var $productSelectric = $('.js-card-variants__item ul');
  $productSelectric.each(function () {
    var $this = $(this);

    if ($this.children().length === 1) {
      $this.closest('.selectric-items').siblings('.selectric').css("background-color", "#f0f0f0");
    }
  });
});

// Checkboxes
// ----------

_globals.$BODY.on('change initCheckboxes', '.js-checkbox input', function (event) {
  var $inp = $(event.target);
  var $label = $inp.closest('.js-checkbox');

  if ($inp.prop('checked')) {
    $label.addClass('is-checked');
  } else {
    $label.removeClass('is-checked');
  }
});
$('.js-checkbox input').trigger('initCheckboxes');

// Radio buttons
// -------------
$(function () {
  _globals.$BODY.on('change initRadio', '.js-radio input', function (event) {
    var $inp = $(event.target);
    var $group = $('[name="' + $inp.attr('name') + '"]');
    var $labels = $group.closest('.js-radio');
    var $selectedItem = $labels.find('input').filter(':checked').closest('.js-radio');

    $labels.removeClass('is-checked');
    $selectedItem.addClass('is-checked');
  });
  $('.js-radio input').trigger('initRadio');
});

// Textarea symbol count
// -------------------

$('.js-textarea-counter').on('keyup paste change', function countLetters() {

  var $textarea = $(this);
  var $count = $textarea.closest('.form__field').find('.textarea-symbol-counter span');
  var $textlength = $textarea.val().length;

  if ($textarea.val().length > 200) {
    $count.closest('.textarea-symbol-counter').css({ color: 'red' });
  } else {
    $count.closest('.textarea-symbol-counter').css({ color: 'black' });
  }

  $count.text($textlength);
});

// Touched inputs state
// --------------------
$(function () {
  $('.form__input, .form__textarea').on('focus', function focusHandler() {
    $(this).closest('.form__field').addClass('is-touched');
  }).on('blur', function blurHandler() {
    var $this = $(this);
    var value = $this.val();

    if (value !== '' && value != null && value !== '+7 (___) ___-__-__') return;

    $this.closest('.form__field').removeClass('is-touched');
  }).on('inputs-init', function inputsInit() {
    var $this = $(this);
    var value = $this.val();

    if (value !== '' && value != null && value !== '+7 (___) ___-__-__') {
      $this.closest('.form__field').addClass('is-touched');
    }
  }).trigger('inputs-init');
});

// Datepicker
// ----------

$('.js-datepicker').datepicker({
  dateFormat: 'dd.mm.yy'
});

// Fileinputs
// ----------

_globals.$DOCUMENT.on('change', '.js-fileinput input', function changeHandler(event) {
  $(event.target).closest('.js-fileinput').find('.js-fileinput-filename').text($(this).val().substring(12));
});

// Filters

$(function () {

  if (!$('.js-toggle-mobile-filters').length) return;

  var openedClass = 'filter-is-opened';

  var url = new URL(window.location);
  if (url.searchParams.get('filter')) {
    _globals.$BODY.addClass(openedClass);
  }

  $(document).on('click', '.js-toggle-mobile-filters', function (e) {
    e.preventDefault();
    $(document.body).toggleClass(openedClass);

    if (_globals.$BODY.hasClass(openedClass)) {
      url = new URL(window.location);
      url.searchParams.set('filter', 'opened');
      window.history.pushState({}, '', url);
    } else {
      url = new URL(window.location);
      if (url.searchParams.get('filter')) {
        window.history.back();
      }
    }
  });

  $(document).on('click.close-mobile-filters', function (e) {
    var $target = $(e.target);
    if ($target.closest('.filter').length || $target.hasClass('js-toggle-mobile-filters')) return;
    if (_globals.$BODY.hasClass(openedClass)) {
      $('.js-toggle-mobile-filters').first().click();
    }
  });

  window.addEventListener('popstate', onPopstate);

  function onPopstate() {
    url = new URL(window.location);
    if (!url.searchParams.get('filter')) {
      _globals.$BODY.removeClass(openedClass);
    } else {
      _globals.$BODY.addClass(openedClass);
    }
  }

  $(document).on('click', '.js-toggle-filter-list', function toggleList(e) {
    e.preventDefault();
    $(this).closest('.js-filter-list').toggleClass('is-expanded');
  });
});

$(function () {

  $('input[type="search"]').each(function showSearchClear() {
    var $searchInput = $(this);
    var $searchForm = $searchInput.closest('.js-search-form');
    var $clearBtn = $searchForm.find('[type="reset"]');
    var touchedClass = 'is-touched';

    if ($searchForm.length < 1) return;

    $searchInput.on('keyup paste change', function () {
      if ($searchInput.val() === '') {
        $searchInput.removeClass(touchedClass);
        return;
      }
      $searchInput.addClass(touchedClass);
    });

    $searchForm.on('reset', function () {
      setTimeout(function () {
        $searchInput.trigger('change');
      }, 0);
    });

    $clearBtn.on('click', function () {
      $searchInput.attr('value', '');
    });
  });

  $('.js-mobile-search .js-search-form input').on('click', function () {
    _globals.$BODY.addClass('search-is-opened');
  });

  $('.js-mobile-search-toggler').on('click', function () {
    _globals.$BODY.removeClass('search-is-opened');
  });
});

},{"./globals.js":3}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$BODY = exports.$HTML = exports.$DOCUMENT = exports.$WINDOW = exports.HEADER_HEIGHT = exports.NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = exports.TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = exports.MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = exports.IS_TOUCH_DEVICE = exports.IS_IE = exports.IS_IOS = exports.IS_DESKTOP = exports.IS_MOBILE = exports.MEDIA_QUERIES1 = undefined;

var _mediaQueriesConfigJson = require('../../media-queries-config.json.js');

var _mediaQueriesConfigJson2 = _interopRequireDefault(_mediaQueriesConfigJson);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Const
// -----

var MEDIA_QUERIES1 = exports.MEDIA_QUERIES1 = _mediaQueriesConfigJson2.default;
var IS_MOBILE = exports.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
var IS_DESKTOP = exports.IS_DESKTOP = !IS_MOBILE;
var IS_IOS = exports.IS_IOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g);
var IS_IE = exports.IS_IE = navigator.appVersion.indexOf('MSIE') !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
var IS_TOUCH_DEVICE = exports.IS_TOUCH_DEVICE = 'ontouchstart' in document;

var HTML = document.documentElement;

HTML.classList.remove('no-js');

if (IS_MOBILE) HTML.classList.add('is-mobile');
if (IS_DESKTOP) HTML.classList.add('is-desktop');
if (IS_IOS) HTML.classList.add('is-ios');
if (IS_IE) HTML.classList.add('is-ie');
if (IS_TOUCH_DEVICE) HTML.classList.add('is-touch-device');

var SMALL_MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.small;
var MOBILE_WIDTH = exports.MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.portrait;
var LANDSCAPE_MOBILE_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.landscape;
var PORTRAIT_TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.portrait;
var TABLET_WIDTH = exports.TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.landscape;
var SMALL_NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.small;
var NOTEBOOK_WIDTH = exports.NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.normal;

var HEADER_HEIGHT = exports.HEADER_HEIGHT = $('.header').height();

// Selectors
// ---------

var $WINDOW = exports.$WINDOW = $(window);
var $DOCUMENT = exports.$DOCUMENT = $(document);
var $HTML = exports.$HTML = $(document.documentElement);
var $BODY = exports.$BODY = $(document.body);

// Custom vh for mobile devices
// ----------------------------
// css example:
// height: calc(var(--vh, 1vh) * 100);

$(window).on('resize initVh', function () {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
}).trigger('initVh');

window.addEventListener('DOMContentLoaded', function () {
  $(window).on('load mousewheel scroll resize headerHeight', function () {
    var header = document.getElementsByClassName('header')[0];
    var hh = header.clientHeight;

    document.documentElement.style.setProperty('--hh', hh + 'px');
  });
});

// Trigger fonts loaded event
// --------------------------

if (document.fonts !== undefined) {
  document.fonts.onloadingdone = function fontsLoadedCallback() {
    $(document).trigger('fontsLoaded');
    $(window).trigger('fontsLoaded');
  };
}

},{"../../media-queries-config.json.js":14}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IS_SMALL_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = exports.IS_TABLET_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = exports.IS_DESKTOP_WIDTH = exports.WINDOW_HEIGHT = exports.WINDOW_WIDTH = undefined;
exports.status = status;

var _globals = require('./globals.js');

/* eslint-disable import/no-mutable-exports */
var WINDOW_WIDTH = exports.WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
var WINDOW_HEIGHT = exports.WINDOW_HEIGHT = _globals.$WINDOW.height();
/* eslint-enable import/no-mutable-exports */
_globals.$WINDOW.resize(function () {
  exports.WINDOW_WIDTH = WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
  exports.WINDOW_HEIGHT = WINDOW_HEIGHT = _globals.$WINDOW.height();
});

var IS_DESKTOP_WIDTH = exports.IS_DESKTOP_WIDTH = function IS_DESKTOP_WIDTH() {
  return WINDOW_WIDTH > _globals.NOTEBOOK_WIDTH;
};
var IS_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = function IS_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= _globals.NOTEBOOK_WIDTH;
};
var IS_SMALL_NOTEBOOK_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = function IS_SMALL_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.TABLET_WIDTH && WINDOW_WIDTH <= _globals.SMALL_NOTEBOOK_WIDTH;
};
var IS_TABLET_WIDTH = exports.IS_TABLET_WIDTH = function IS_TABLET_WIDTH() {
  return WINDOW_WIDTH >= _globals.PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= _globals.TABLET_WIDTH;
};
var IS_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = function IS_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.MOBILE_WIDTH;
};
var IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = function IS_LANDSCAPE_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.LANDSCAPE_MOBILE_WIDTH;
};
var IS_SMALL_MOBILE_WIDTH = exports.IS_SMALL_MOBILE_WIDTH = function IS_SMALL_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.SMALL_MOBILE_WIDTH;
};

// Response status
function status(response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

},{"./globals.js":3}],5:[function(require,module,exports){
'use strict';

(function ($) {
    $(document).ready(function (e) {
        $(".js-fancybox").fancybox({
            helpers: {
                overlay: {
                    locked: false
                }
            }
        });

        $('.js-form').submit(function () {
            $(this).ajaxSubmit({
                success: function success(responseText, statusText, xhr, $form) {
                    alert('Успешно!');
                },

                error: function error(response) {
                    var errors = response.responseJSON.errors;

                    for (var errorKey in errors) {
                        var errorValue = errors[errorKey];

                        alert(errorValue);
                        break;
                    }
                },

                clearForm: true,
                resetForm: true
            });

            return false;
        });

        $(function () {
            $('[data-toggle="popover"]').popover();
        });

        var ui = {
            lang: $('.js-lang'),
            basketBtn: $('.js-basket-btn'),
            basketPanel: $('.js-basket-panel'),
            basketContent: $('#js-panel-basket'),
            favoriteBtn: $('.js-favorite-btn'),
            favoriteContent: $('#js-panel-favorite'),
            sidebarOpen: $('.js-sidebar-open'),
            sidebarClose: $('.js-sidebar-close'),
            mainSidebar: $('.js-main-sidebar'),
            choiceBtn: $('.js-choice-btn'),
            choiceSidebar: $('.js-choice-sidebar'),
            sidebarChoiceClose: $('.js-sidebar-choice-close'),
            pageWrapper: $('.js-page-wrapper'),
            collapsePanel: $('.js-collapse-panel'),
            collapseBtn: $('.js-collapse-btn'),
            collapseBody: $('.js-collapse-body'),
            action: $('.js-action'),
            actionContent: $('.js-action-content'),
            actionClose: $('.js-action-close'),
            tabBtn1: $('.js-tab-btn1'),
            tabBtn2: $('.js-tab-btn2'),
            tabBtn3: $('.js-tab-btn3'),
            tabContent1: $('.js-tab-content1'),
            tabContent2: $('.js-tab-content2'),
            tabContent3: $('.js-tab-content3'),
            closeTab: $('.js-close-tab'),
            mapControl1: $('.js-map-control1'),
            mapControl2: $('.js-map-control2'),
            mapControl3: $('.js-map-control3'),
            mapControl4: $('.js-map-control4'),
            mapColor: $('.js-map-color'),
            map1: $('.js-map1'),
            map2: $('.js-map2'),
            map3: $('.js-map3'),
            map4: $('.js-map4')
            //detailsBtn:             $('.js-details-btn'),
            //detailsCard:            $('.js-details-card'),
            //detailsClose:           $('.js-details-close')
        };

        //Логика открывания деталей карточки

        /*ui.detailsBtn.on('click', function () {
            ui.detailsCard.addClass('open');
            return false;
        });*/

        /*ui.detailsClose.on('click', function () {
            ui.detailsCard.removeClass('open');
            return false;
        });*/

        // Логика переключения языка

        /*ui.lang.on('click', function () {
            ui.lang.removeClass('active');
            $(this).toggleClass('active');
        });*/

        // Логика работы корзины

        ui.basketBtn.on('click', function () {
            $(this).toggleClass('open');
            ui.favoriteBtn.removeClass('open');
            if ($(this).hasClass('open')) {
                ui.basketPanel.addClass('open');
                ui.basketContent.show();
                ui.favoriteContent.hide();
            } else {
                ui.basketPanel.removeClass('open');
            }
        });

        // Логика работы избранного

        ui.favoriteBtn.on('click', function () {
            $(this).toggleClass('open');
            ui.basketBtn.removeClass('open');
            if ($(this).hasClass('open')) {
                ui.basketPanel.addClass('open');
                ui.favoriteContent.show();
                ui.basketContent.hide();
            } else {
                ui.basketPanel.removeClass('open');
            }
        });

        // Логика открывания/закрывания боковой панели
        $(document).on('click', '.js-sidebar-open', function () {
            ui.mainSidebar.addClass('open');
            setTimeout(delayFunc, 700);
            function delayFunc() {
                ui.pageWrapper.addClass('mobile-hidden portrait-tablet-hidden');
            }
        });

        $(document).on('click', '.js-sidebar-close', function () {
            ui.mainSidebar.removeClass('open');
            ui.pageWrapper.removeClass('mobile-hidden portrait-tablet-hidden');
        });

        // Логика открывания/закрывания боковой выбора фильтра

        ui.choiceBtn.on('click', function () {
            ui.choiceSidebar.toggleClass('open');
            setTimeout(delayFunc, 700);
            function delayFunc() {
                ui.pageWrapper.addClass('mobile-hidden portrait-tablet-hidden');
            }
        });

        ui.sidebarChoiceClose.on('click', function () {
            ui.choiceSidebar.removeClass('open');
            ui.pageWrapper.removeClass('mobile-hidden portrait-tablet-hidden');
        });

        //Логика работы акций

        ui.action.on('click', function () {
            $(this).addClass('hidden');
            ui.actionContent.removeClass('hidden');
        });

        ui.actionClose.on('click', function () {
            ui.actionContent.addClass('hidden');
            ui.action.removeClass('hidden');
        });

        //Логика работы табов в карточке товара

        function clearProduct() {
            ui.tabBtn1.removeClass('active');
            ui.tabBtn2.removeClass('active');
            ui.tabBtn3.removeClass('active');
            ui.tabContent1.removeClass('active');
            ui.tabContent2.removeClass('active');
            ui.tabContent3.removeClass('active');
        }

        ui.tabBtn1.on('click', function () {
            clearProduct();
            $(this).addClass('active');
            ui.tabContent1.addClass('active');
            $(document).trigger('tabChanged');
        });

        ui.tabBtn2.on('click', function () {
            clearProduct();
            $(this).addClass('active');
            ui.tabContent2.addClass('active');
            $(document).trigger('tabChanged');
        });

        ui.tabBtn3.on('click', function () {
            clearProduct();
            $(this).addClass('active');
            ui.tabContent3.addClass('active');
            $(document).trigger('tabChanged');
        });

        ui.closeTab.on('click', function () {
            clearProduct();
        });
    });

    $('.js-news-product-slider').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        responsive: {
            0: {
                items: 1,
                loop: true,
                nav: true
            },
            600: {
                items: 3,
                loop: true,
                nav: true
            },
            1025: {
                items: 5,
                loop: true,
                nav: true
            }
        }
    });

    $('.collapse-panel__title').on('click', function () {
        $($(this).data('target')).collapse('toggle');
    });

    $('.form-agreement input[type="checkbox"]').each(function () {
        var $checkbox = $(this),
            $submit = $checkbox.closest('form').find('.form-submit input');
        $checkbox.on('change initCheckbox', function () {
            if ($checkbox.prop('checked')) {
                if (!$submit.data('disable-block-by-order-sum')) {
                    $submit.data('disable-block-by-agreement', false).prop('disabled', false);
                }
            } else {
                $submit.data('disable-block-by-agreement', true).prop('disabled', true);
            }
        }).trigger('initCheckbox');
    });

    $('input[type="tel"], .phone-input').each(function () {
        var $input = $(this);
        var defaultPlaceholder = $input.attr('placeholder') || '+7 (___) ___-__-__';
        var defaultPlaceholderMask = '+7 (___) ___-__-__';
        var phoneMask = '+7 (000) 000-00-00';
        var pasted = false;

        $input.attr('placeholder', defaultPlaceholder);

        $input.on('focus', function () {
            $input.attr('placeholder', defaultPlaceholderMask);
        }).on('blur', function () {
            $input.attr('placeholder', defaultPlaceholder);
        });

        $input.on('paste drop', function () {
            var isEmpty = !$input.val();
            var element = $input.get(0);
            var selectionLength = element.selectionEnd - element.selectionStart;

            if (!isEmpty && $input.val().length !== selectionLength) {
                return;
            }

            pasted = true;
        });

        $input.on('input', function (e) {
            var value = $input.val();

            if (value.startsWith('8')) {
                if (pasted && value.length !== 11) {
                    pasted = false;
                    return;
                }

                pasted = false;

                e.stopPropagation();

                value = value.replace('8', '+7');
                $input.val(value);
            }
        });

        $input.mask(phoneMask);
    });
})(jQuery);

},{}],6:[function(require,module,exports){
'use strict';

$(function () {

  function initializePhoneMasks() {
    $('input[type="tel"], .js-masket-tel').each(function initMask() {
      var $input = $(this);
      var defaultPlaceholder = $input.attr('placeholder') || '';
      var defaultPlaceholderMask = '+7 (___) ___-__-__';
      var phoneMask = '+7 (000) 000-00-00';
      var pasted = false;

      $input.on('focus', function () {
        $input.attr('placeholder', defaultPlaceholderMask);
      }).on('blur', function () {
        $input.attr('placeholder', defaultPlaceholder);
      });

      $input.on('paste drop', function () {
        var isEmpty = !$input.val();
        var element = $input.get(0);
        var selectionLength = element.selectionEnd - element.selectionStart;

        if (!isEmpty && $input.val().length !== selectionLength) {
          return;
        }

        pasted = true;
      });

      $input.on('input', function (e) {
        var value = $input.val();

        if (value.startsWith('8')) {
          if (pasted && value.length !== 11) {
            pasted = false;
            return;
          }

          pasted = false;

          e.stopPropagation();

          value = value.replace('8', '+7');
          $input.val(value);
        }
      });

      $input.mask(phoneMask);
    });
  }

  $(window).on('initializePhoneMasks', initializePhoneMasks).trigger('initializePhoneMasks');
});

},{}],7:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

$(function () {
  var $menus = $('.js-menu');

  if (!_globals.IS_TOUCH_DEVICE) {
    $menus.on('mouseenter', 'li', function mouseenterHandler() {
      var $this = $(this);

      clearTimeout($this.data('unhoverTimeout'));

      if ($this.hasClass('has-dropdown')) {
        $this.data('hoverTimeout', setTimeout(function () {
          $this.addClass('is-hovered');
        }, 0));
      } else {
        $this.addClass('is-hovered');
      }
    });

    $menus.on('mouseleave.js-menu', 'li', function mouseleaveHandler() {
      var $this = $(this);

      clearTimeout($this.data('hoverTimeout'));

      $this.data('unhoverTimeout', setTimeout(function () {
        $this.removeClass('is-hovered');
      }, 0));
    });
  }

  if (_globals.IS_TOUCH_DEVICE) {
    $menus.on('click.js-m-menu', 'a', function clickHandler(e) {
      e.preventDefault();

      var $anchor = $(this);
      var $parent = $anchor.parent();
      var hasDropdown = $parent.hasClass('has-dropdown');
      var isHovered = $parent.hasClass('is-hovered');
      var link = $anchor.attr('href');

      $parent.siblings().removeClass('is-hovered');

      if (!hasDropdown && link) {
        window.location.href = $anchor.attr('href');
      } else if (isHovered && link) {
        window.location.href = link;
      } else {
        $parent.toggleClass('is-hovered');
        if ($parent.hasClass('is-hovered')) {
          $(document).on('click.close-menu-by-backdrop', function (event) {
            if (!$(event.target).closest('.js-menu').length) {
              $parent.removeClass('is-hovered');
              $(document).off('click.close-menu-by-backdrop');
            }
          });
        } else {
          $(document).off('click.close-menu-by-backdrop');
        }
      }
    });

    $('.js-toggle-dropdown').click(function toggleDropdown() {
      var $parent = $(this).parent();
      $parent.toggleClass('is-hovered').siblings().removeClass('is-hovered');
      if ($parent.hasClass('is-hovered')) {
        $(document).on('click.close-menu-by-backdrop', function (event) {
          if (!$(event.target).closest('.js-menu').length) {
            $parent.removeClass('is-hovered');
            $(document).off('click.close-menu-by-backdrop');
          }
        });
      } else {
        $(document).off('click.close-menu-by-backdrop');
      }
    });
  }
});

$(function () {

  var $body = $('body');
  var openedClass = 'menu-is-opened';

  $body.on('click', '.js-toggle-mobile-menu', function (e) {
    e.preventDefault();
    $body.toggleClass(openedClass);

    if ($body.hasClass(openedClass)) {
      $body.on('click.close-menu', function (ev) {
        if ($(ev.target).closest('.js-menu').length || $(ev.target).hasClass('js-toggle-mobile-menu') || $(ev.target).closest('.mobile-menu').length) return;

        closeMenu();
      });
    }
  });

  function closeMenu() {
    $body.removeClass(openedClass).off('click.close-menu');
  }

  $body.on('click', '.js-close-mobile-menu', function () {
    return closeMenu();
  });
});

},{"./globals.js":3}],8:[function(require,module,exports){
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; /* global initCardApp */

var _globals = require('./globals.js');

var OVERLAY_LOADER_DEFAULTS = {
  hideSelector: '.js-close-popup',
  fixedElements: '.js-fixed-element',
  onShow: function onShow() {
    _globals.$DOCUMENT.trigger('popupShown');
  }
};

_globals.$DOCUMENT.on('click.overlay-open', '.js-overlay', function clickHandler(e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, _extends({
    node: $popup
  }, OVERLAY_LOADER_DEFAULTS));
});

_globals.$DOCUMENT.on('click.overlay-close', '.js-close-popup', function clickHandler(e) {
  e.preventDefault();

  $.overlayLoader(false, _extends({
    node: $(this).parent()
  }, OVERLAY_LOADER_DEFAULTS));
});

_globals.$DOCUMENT.on('click.map-overlay-open', '.js-map-content-overlay', function clickHandler(e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, _extends({
    node: $popup
  }, OVERLAY_LOADER_DEFAULTS, {
    onShow: function onShow() {
      _globals.$DOCUMENT.trigger('initMapTabSliders');
    }
  }));
});

_globals.$DOCUMENT.on('click.overlay-open', '.js-ajax-overlay', function clickHandler(e) {
  e.preventDefault();

  var $self = $(this);
  var $node = $($self.attr('href'));

  $.ajax({
    type: $self.data('type'),
    url: $self.data('ajax-url'),
    data: parseAjaxOverlayParams($self.data('ajax-params')),
    timeout: 10000,
    success: function success(data) {
      $node.find('.js-ajax-data').html(data);
      // setTimeout(() => {
      //   $(window).trigger('resize.uniloader');
      // }, 100);
      initCardApp('#' + $node.find('.js-card-app').attr('id'));
      setTimeout(function () {
        $(document).trigger('ajaxPopupLoaded');
      }, 350);
      $.overlayLoader(true, _extends({
        node: $node
      }, OVERLAY_LOADER_DEFAULTS));
    },
    error: function error() {}
  });
});

function parseAjaxOverlayParams(str) {
  if (str === undefined || str === '') return {};

  if ((typeof str === 'undefined' ? 'undefined' : _typeof(str)) === 'object') return str;

  var jsonParams = JSON.parse(str);
  if (jsonParams) return jsonParams;

  var obj = {};
  var array = str.split('&');

  array.forEach(function (value) {
    var a = value.split('=');
    /* eslint-disable prefer-destructuring */
    obj[a[0]] = a[1];
    /* eslint-enable prefer-destructuring */
    return true;
  });

  return obj;
}

// Autofocus
_globals.$DOCUMENT.on('overlayLoaderShow', function (e, $node) {
  $node.find('.js-autofocus-inp').focus();
});

// Coockie alert on homepage
_globals.$BODY.on('click', '.js-close-alert', function closeAlert(e) {
  e.preventDefault();
  var $parent = $(this).closest('.js-alert');

  $parent.removeClass('--shown');
  localStorage.setItem('shown', 'yes');
});

/*
// Вывод попапа в карточке товара (компаньоны)
$DOCUMENT.on('click', '.js-card-popup', function(event) {
  event.preventDefault();
  event.stopPropagation();
  const $this = $(this);
  const $popup = $($this.attr('href'));
  const $selectedElData = $this.data('image-id');

  $popup.toggleClass('is-active');

  $popup.find('.tabs-nav li a').each(function() {
    let $el = $(this).attr('href').split('#tab-').join('');

    if($el == $selectedElData) {
      $(this).click();
    }
  });

  if($WINDOW.width() <= MOBILE_WIDTH) {
    $('html, body').animate({
    scrollTop: $('#combination-popup').offset().top - $('.header').height() - 15
    }, 200, 'linear');
  }
});

$('.js-close-card-popup').click(function closePopup(e) {
  e.preventDefault();
  const $this = $(this);
  const $parent = $this.parent();

  $parent.removeClass('is-active');

  if($WINDOW.width() <= MOBILE_WIDTH && $('.card-page__variants')) {
    $('html, body').animate({
    scrollTop: $('.card-page__variants').offset().top - $('.header').height() - 15
    }, 200, 'linear');
  }
});
*/

$(function () {
  // Поиск цветов в попапе из карточки
  _globals.$DOCUMENT.on('keyup paste change', '.js-search', function colorSearch() {
    var $inpValue = $(this).val();
    var $items = $('.js-search-area a');

    $items.each(function includesFunc() {
      var $this = $(this);
      if (!$this.find('.card-page__variant-text').text().includes($inpValue)) {
        if (!$this.find('.js-color').data('color').includes($inpValue)) {
          $(this).fadeOut();
        }
      } else {
        $(this).fadeIn();
      }
    });
  });
});

},{"./globals.js":3}],9:[function(require,module,exports){
'use strict';

// Avoid `console` errors in browsers that lack a console.
// -------------------------------------------------------------
(function () {
    var method;
    var noop = function noop() {};
    var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'];
    var length = methods.length;
    var console = window.console = window.console || {};

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
})();

// jQuery Plugins
// -------------------------------------------------------------
(function ($) {
    $(document).ready(function (e) {});
})(jQuery);

},{}],10:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var _helpers = require('./helpers.js');

// Scroll to
// ---------

_globals.$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function clickHandler(e) {
  e.preventDefault();

  var $link = $(this);
  var $elementToScroll = $($link.attr('href'));
  var speed = $link.data('speed') || 300;
  // const offset = $link.data('offset') || $('.header').length ? $('.header').height() : 0;
  var offset = $link.data('offset') || 0;

  _globals.$WINDOW.scrollTo($elementToScroll, { duration: speed, offset: offset });
});

// Scrolling to top
// ----------------
$(function () {
  if (!(0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) {
    var $goTopButton = $('<div class="btn-back-top"><a href="#top" class="btn-back-top__link">Наверх</a></div>');

    $goTopButton.click(function () {
      _globals.$WINDOW.scrollTo(0, 200);
    });

    $(document.body).append($goTopButton);
  }
});

(function () {
  var scrollPos = $(window).scrollTop();
  var scrollCheckingTimeout = void 0;

  _globals.$WINDOW.scroll(function () {
    if (scrollCheckingTimeout) {
      clearTimeout(scrollCheckingTimeout);
    }
    scrollCheckingTimeout = setTimeout(function () {
      return checkScrollPos();
    }, 0);
  });

  function checkScrollPos() {
    var newScrollPos = _globals.$WINDOW.scrollTop();
    if (newScrollPos > $('.header').height()) {

      $(document.body).addClass('scrolledFromTop');
      $('.js-menu li').removeClass('is-hovered'); // refs #72387
      if (newScrollPos > scrollPos) {
        $(document.body).addClass('scrolledDown').removeClass('scrolledUp');
      } else if (newScrollPos < scrollPos) {
        $(document.body).addClass('scrolledUp').removeClass('scrolledDown');
      }
    } else {
      $(document.body).removeClass('scrolledFromTop scrolledDown');
    }
    scrollPos = newScrollPos;
  }
})();

// Inview
// ------

$(function () {
  $('.js-inview').each(function inviewHandler() {
    var _this = this;

    $(this).bind('inview', function (event, isInView, visiblePartX, visiblePartY) {
      // $(this).bind('inview', (event, isInView) => {
      if ($(_this).hasClass('js-inview-top') && isInView || (!(0, _helpers.IS_SMALL_MOBILE_WIDTH)() ? visiblePartY === 'top' || visiblePartY === 'center' : isInView)
      // isInView
      ) {
          $(_this).addClass('inview').unbind('inview');
          $(_this).trigger('inviewTriggered');
        }
      $(window).trigger('inviewTriggered');
    });
  });
});

// Second step basket scroll to error

$(function () {
  $('.js-basket-submit').click(function () {
    setTimeout(function () {
      var $firstError = $('.basket-form .form__field.error')[0];
      _globals.$WINDOW.scrollTo($firstError, { duration: 300, offset: -50 });
    }, 500);
  });

  $('.js-catering-form-btn').click(function () {
    setTimeout(function () {
      var $firstError = $('.catering-form .form__field.error')[0];
      $('.append-scrollbars').scrollTo($firstError, { duration: 300, offset: -50 });
    }, 500);
  });
});

// Catering form scroll to error

$(function () {
  $('.js-catering-form-btn').click(function () {
    setTimeout(function () {
      var $firstError = $('.catering-form .form__field.error')[0];
      $('.append-scrollbars').scrollTo($firstError, { duration: 300, offset: -50 });
    }, 500);
  });
});

},{"./globals.js":3,"./helpers.js":4}],11:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

$(function () {
  $('.js-homepage-rotator').each(function createSlider() {
    var $this = $(this);
    var $controls = $this.closest('[data-slider-root]').find('[data-slider-controls]');
    var $status = $controls.find('[data-slider-counter]');

    $this.on('init reInit beforeChange', function (event, slick, currentSlide, nextSlide) {
      var i = (nextSlide || 0) + 1;
      var count = '' + slick.slideCount;

      $status.html(i + '/' + count);
    });

    $this.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      arrows: true,
      dots: false,
      appendArrows: $controls,
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 1000,
      pauseOnHover: true,
      fade: true,
      draggable: false,
      waitForAnimate: false,
      responsive: [{
        breakpoint: _globals.LANDSCAPE_MOBILE_WIDTH + 1,
        settings: "unslick"
      }]
    });
  });

  $('.js-mobile-actions-slider').each(function createSlider() {
    var $this = $(this);

    if (_globals.$WINDOW.width() > _globals.LANDSCAPE_MOBILE_WIDTH) return;

    $this.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      arrows: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 2000,
      touchThreshold: 10
    });
  });

  $('.js-letters-slider').each(function createSlider() {
    var $slider = $(this);
    var $status = $slider.closest('[data-slider-root]').find('[data-slider-counter]');

    $slider.on('init reInit beforeChange', function (event, slick, currentSlide, nextSlide) {
      var i = (nextSlide || 0) + 1;
      var count = '' + slick.slideCount;

      $status.html(i + ' / <span class="green"> ' + count + '</span>');
    });

    $slider.slick({
      slidesToShow: 4,
      slidesToScroll: 4,
      infinite: false,
      arrows: true,
      dots: false,
      touchThreshold: 10,
      lazyLoad: 'ondemand',
      responsive: [{
        breakpoint: 1024 + 1,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }, {
        breakpoint: 896 + 1,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }, {
        breakpoint: 700 + 1,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });
  });

  $('.js-features-mobile-slider').each(function createSlider() {
    var $this = $(this);
    var $status = $this.closest('[data-slider-root]').find('[data-slider-counter]');

    $this.on('init reInit beforeChange', function (event, slick, currentSlide, nextSlide) {
      var i = (nextSlide || 0) + 1;
      var count = '' + slick.slideCount;

      $status.html(i + '/<span class="green">' + count + '</span>');
    });

    if (_globals.$WINDOW.width() > _globals.LANDSCAPE_MOBILE_WIDTH) return;

    $this.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      arrows: true,
      dots: false,
      autoplay: false,
      touchThreshold: 10,
      adaptiveHeight: true
    });
  });

  $('.js-vitrine-mobile-slider').each(function createSlider() {
    var $this = $(this);
    var $controls = $this.parent().find('.js-slider-controls');
    var isDoublet = $this.attr('data-slider-doublet');

    if (_globals.$WINDOW.width() > _globals.LANDSCAPE_MOBILE_WIDTH) return;

    $this.on('init afterChange', function () {
      var $scroll = $controls.find('.slick-dots');
      if (!$scroll.length) return;

      if ($scroll.width() > $(window).width() - 40) {
        $controls.addClass('with-scroller');
      }

      $scroll.scrollTo($controls.find('.slick-active'), {
        duration: 300,
        offset: -$controls.width() / 2
      });
      setTimeout(function () {
        if ($scroll.scrollLeft() === 0) {
          $controls.addClass('is-at-start');
        } else {
          $controls.removeClass('is-at-start');
        }
        if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
          $controls.addClass('is-at-end');
        } else {
          $controls.removeClass('is-at-end');
        }
      }, 200);
    });

    $this.slick({
      slidesToShow: 2.5,
      slidesToScroll: 2,
      dots: true,
      rows: isDoublet ? 2 : 1,
      appendDots: $controls,
      infinite: false,
      arrows: false,
      touchThreshold: 10,
      responsive: [{
        breakpoint: _globals.MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      }]
    });
  });

  $('.js-customers-slider').each(function createSlider() {
    var $this = $(this);
    var $controls = $this.parent().find('.js-slider-controls');

    $this.on('init afterChange', function () {
      var $scroll = $controls.find('.slick-dots');
      if (!$scroll.length) return;

      if ($scroll.width() >= $(window).width() - 60) {
        $controls.addClass('with-scroller');
      }

      $scroll.scrollTo($controls.find('.slick-active'), {
        duration: 300,
        offset: -$controls.width() / 2
      });
      setTimeout(function () {
        if ($scroll.scrollLeft() === 0) {
          $controls.addClass('is-at-start');
        } else {
          $controls.removeClass('is-at-start');
        }
        if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
          $controls.addClass('is-at-end');
        } else {
          $controls.removeClass('is-at-end');
        }
      }, 200);
    });

    $this.slick({
      slidesToShow: 7,
      slidesToScroll: 4,
      infinite: true,
      arrows: true,
      dots: false,
      touchThreshold: 10,
      responsive: [{
        breakpoint: _globals.SMALL_NOTEBOOK_WIDTH + 1,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3
        }
      }, {
        breakpoint: _globals.TABLET_WIDTH + 1,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      }, {
        breakpoint: _globals.MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
          dots: true,
          appendDots: $controls,
          arrows: false,
          infinite: false
        }
      }]
    });
  });

  $('.js-card-gallery').each(function createSlider() {
    var $this = $(this);
    var $controls = $this.closest('[data-slider-root]').find('.js-slider-controls');

    $this.on('init afterChange', function () {
      var $scroll = $controls.find('.slick-dots');
      if (!$scroll.length) return;

      if ($scroll.width() >= 300) {
        $controls.addClass('with-scroller');
      }

      $scroll.scrollTo($controls.find('.slick-active'), {
        duration: 300,
        offset: -$controls.width() / 3
      });
      setTimeout(function () {
        if ($scroll.scrollLeft() === 0) {
          $controls.addClass('is-at-start');
        } else {
          $controls.removeClass('is-at-start');
        }
        if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
          $controls.addClass('is-at-end');
        } else {
          $controls.removeClass('is-at-end');
        }
      }, 100);
    });

    $this.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      fade: true,
      arrows: false,
      dots: true,
      appendDots: $controls,
      touchThreshold: 10
    });
  });

  $('.js-reviews-slider').each(function createSlider() {
    var $this = $(this);
    var $controls = $this.parent().find('.js-slider-controls');
    var haveArrows = $this.attr('data-slider-with-arrows');

    $this.on('init afterChange', function () {
      var $scroll = $controls.find('.slick-dots');
      if (!$scroll.length) return;

      if ($scroll.width() > $(window).width() - 60) {
        $controls.addClass('with-scroller');
      }

      $scroll.scrollTo($controls.find('.slick-active'), {
        duration: 300,
        offset: -$controls.width() / 2
      });
      setTimeout(function () {
        if ($scroll.scrollLeft() === 0) {
          $controls.addClass('is-at-start');
        } else {
          $controls.removeClass('is-at-start');
        }
        if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
          $controls.addClass('is-at-end');
        } else {
          $controls.removeClass('is-at-end');
        }
      }, 200);
    });

    $this.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: false,
      arrows: haveArrows ? true : false,
      appendArrows: $controls,
      dots: false,
      touchThreshold: 10,
      responsive: [{
        breakpoint: _globals.TABLET_WIDTH + 1,
        settings: {
          slidesToShow: 2,
          dots: true,
          appendDots: $controls
        }
      }, {
        breakpoint: _globals.MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 1,
          dots: true,
          appendDots: $controls,
          arrows: false
        }
      }]
    });
  });

  $('.js-related-slider').each(function createSlider() {
    var $this = $(this);
    var $controls = $this.parent().find('.js-slider-controls');
    var haveArrows = $this.attr('data-slider-with-arrows');

    $this.on('init afterChange', function () {
      var $scroll = $controls.find('.slick-dots');
      if (!$scroll.length) return;

      if ($scroll.width() > $(window).width() - 60) {
        $controls.addClass('with-scroller');
      }

      $scroll.scrollTo($controls.find('.slick-active'), {
        duration: 300,
        offset: -$controls.width() / 2
      });
      setTimeout(function () {
        if ($scroll.scrollLeft() === 0) {
          $controls.addClass('is-at-start');
        } else {
          $controls.removeClass('is-at-start');
        }
        if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
          $controls.addClass('is-at-end');
        } else {
          $controls.removeClass('is-at-end');
        }
      }, 200);
    });

    $this.slick({
      slidesToShow: 5,
      slidesToScroll: 2,
      infinite: false,
      arrows: haveArrows ? true : false,
      appendArrows: $controls,
      dots: false,
      touchThreshold: 10,
      responsive: [{
        breakpoint: _globals.NOTEBOOK_WIDTH + 1,
        settings: {
          slidesToShow: 4
        }
      }, {
        breakpoint: 1280 + 1,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: _globals.LANDSCAPE_MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
          dots: true,
          appendDots: $controls,
          arrows: false
        }
      }, {
        breakpoint: 480 + 1,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          dots: true,
          appendDots: $controls,
          arrows: false
        }
      }]
    });
  });

  $('.js-gallery-slider').each(function createSlider() {
    var $this = $(this);
    var $controls = $this.closest('[data-slider-root]').find('[data-slider-controls]');

    $this.on('init afterChange', function () {
      var $scroll = $controls.find('.slick-dots');
      if (!$scroll.length) return;

      if ($scroll.width() >= 300) {
        $controls.addClass('with-scroller');
      }

      $scroll.scrollTo($controls.find('.slick-active'), {
        duration: 300,
        offset: -$scroll.width() / 3
      });
      setTimeout(function () {
        if ($scroll.scrollLeft() === 0) {
          $controls.addClass('is-at-start');
        } else {
          $controls.removeClass('is-at-start');
        }
        if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
          $controls.addClass('is-at-end');
        } else {
          $controls.removeClass('is-at-end');
        }
      }, 200);
    });

    $this.slick({
      slidesToShow: 2,
      slidesToScroll: 2,
      infinite: true,
      arrows: true,
      dots: true,
      appendDots: $controls,
      appendArrows: $controls,
      draggable: true,
      responsive: [{
        breakpoint: _globals.TABLET_WIDTH + 1,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }]
    });
  });
});

$(function () {
  $('.js-portfolio-slider').each(function createSliderHandler() {
    createSlider(this);
  });

  function createSlider(slider) {
    var $this = $(slider);
    var $controls = $this.siblings('.js-portfolio-slider-controls');

    if (_globals.$WINDOW.width() > _globals.MOBILE_WIDTH) return;

    $this.on('init afterChange', function () {
      var $scroll = $controls.find('.slick-dots');
      if (!$scroll.length) return;

      if ($scroll.width() >= $(window).width() - 60) {
        $controls.addClass('with-scroller');
      }

      $scroll.scrollTo($controls.find('.slick-active'), {
        duration: 300,
        offset: -$controls.width() / 2
      });
      setTimeout(function () {
        if ($scroll.scrollLeft() === 0) {
          $controls.addClass('is-at-start');
        } else {
          $controls.removeClass('is-at-start');
        }
        if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
          $controls.addClass('is-at-end');
        } else {
          $controls.removeClass('is-at-end');
        }
      }, 200);
    });

    $this.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      arrows: false,
      dots: true,
      appendDots: $controls,
      adaptiveHeight: true,
      autoplay: false,
      touchThreshold: 10
    });
  }

  $('.js-toggle-portfolio-list').click(function toggleSlider(e) {
    e.preventDefault();
    var $this = $(this);
    var expandedClass = 'is-expanded';
    var $container = $this.closest('[data-slider-root]');
    var $slider = $container.find('.js-portfolio-slider');

    if ($container.hasClass('is-expanded')) {
      $slider.removeClass('no-slider');
      createSlider($slider);
    } else if ($slider.hasClass('slick-initialized')) {
      $slider.slick('unslick').addClass('no-slider');
    }

    // $(document).trigger('lazyUpdate');
    _globals.$WINDOW.scrollTo($('.no-slider'), { offset: -200 });
    $container.toggleClass(expandedClass);
  });
});

},{"./globals.js":3}],12:[function(require,module,exports){
'use strict';

$(function () {

  $('.js-spoiler').each(function spoilerHandler() {
    var $this = $(this);
    $(window).on('spoilersInit resize fontsLoaded', function () {
      var isExpanded = $this.hasClass('is-expanded');
      var isInsideHiddenTab = $this.closest('.tabs-panel:not(.is-active)').length;
      var isInsideCollapsedSpoiler = $this.closest('.js-spoiler:not(.is-expanded)').length;
      var isInsideFilter = $this.closest('#filter-aside').length;
      var $el = $this.find('.js-spoiler-body').first();
      var el = $el[0];
      if (!el) return;
      $('.js-spoiler-body').each(function addTempState() {
        $(this)[0].style.setProperty('transition', 'none');
        $(this).find('ul').addClass('is-expanded-for-calc');
      });
      if (!isExpanded) $this.addClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').addClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').addClass('is-active');
      if (isInsideFilter) $('#filter-aside')[0].style.setProperty('display', 'block');
      el.style.setProperty('--max-height', '');
      el.style.setProperty('--max-height', $el.outerHeight() + 'px');
      if (!isExpanded) $this.removeClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').removeClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').removeClass('is-active');
      if (isInsideFilter) $('#filter-aside')[0].style.setProperty('display', '');
      $('.js-spoiler-body').each(function removeTempState() {
        $(this)[0].style.setProperty('transition', '');
        $(this).find('ul').removeClass('is-expanded-for-calc');
      });
    });

    if ($this.hasClass('js-save-spoiler-state')) {
      if (localStorage.getItem('SPOILER_STATE_' + $this.attr('id')) === 'true') {
        $this.addClass('is-expanded');
      }
    }
  });
  setTimeout(function () {
    $(window).trigger('spoilersInit');
  }, 0);

  $(document).on('click', '.js-toggle-spoiler', function toggleClickHandler(e) {
    e.preventDefault();
    var $this = $(this);
    var $spoiler = $this.closest('.js-spoiler');
    var scrollAfter = $this.hasClass('js-scroll-to-closed-spoiler');
    var saveState = $spoiler.hasClass('js-save-spoiler-state');
    $spoiler.toggleClass('is-expanded');
    if (scrollAfter && !$spoiler.hasClass('is-expanded')) {
      $(window).scrollTo($spoiler, { duration: 200, offset: -180 });
    }
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
    if ($this.hasClass('js-close-siblings')) {
      $spoiler.siblings('.js-spoiler').removeClass('is-expanded');
    }
    if (saveState) {
      localStorage.setItem('SPOILER_STATE_' + $spoiler.attr('id'), $spoiler.hasClass('is-expanded'));
    }
  });

  $('.js-toggle-mobile-spoiler').click(function mobileToggleClickHandler(e) {
    e.preventDefault();
    var $spoiler = $(this).closest('.js-spoiler');

    if ($(window).width() > 896) return;
    $spoiler.toggleClass('is-expanded');

    $(window).scrollTo($spoiler, { duration: 200, offset: -220 });
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
  });

  $('.js-toggle-menu-items').click(function toggleMenuItems(e) {
    e.preventDefault();
    e.stopPropagation();

    var $this = $(this);
    var $parent = $this.parent().parent();

    $parent.toggleClass('is-expanded');
  });

  $('.js-header-phones').click(function openHeaderPhones(e) {
    var $this = $(this);
    var $toggler = $this.find('.js-header-phones-toggler');

    if (e.target == this) {
      $this.toggleClass('is-expanded');
    }

    $toggler.click(function closeHeaderPhones() {
      $this.toggleClass('is-expanded');
    });
  });
});

window.addEventListener('DOMContentLoaded', function () {
  $(function () {
    $('.js-text').each(function (index, element) {
      if ($(element).height() < 150) {
        $(element).find('.js-review-toggler').remove();
      } else {
        $(element).css('max-height', '110px');
      }
    });

    $(document).on('click', '.js-review-toggler', function toggleReviewsText(event) {
      event.preventDefault();

      var $this = $(this);
      var $text = $this.closest('.js-text');

      $text.toggleClass('is-expanded');
    });
  });
});

},{}],13:[function(require,module,exports){
'use strict';

$(function () {
  $('.js-tabs .tabs-nav li a').click(function clickHandler(e) {
    e.preventDefault();

    var $this = $(this);
    var $panel = $($this.attr('href'));

    $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
    $panel.closest('.tabs').find('.tabs-panel').removeClass('is-active').hide();
    $panel.fadeIn(function () {
      return $(document).trigger('tab-changed');
    }).addClass('is-active');
  });
});

$(function () {
  $('.js-tabs').each(function handler() {
    $(this).find('.tabs-nav li:first a').click();
  });
});

},{}],14:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  "mobile": {
    "landscape": 896,
    "portrait": 700,
    "small": 480
  },
  "tablet": {
    "landscape": 1024,
    "portrait": 768
  },
  "notebook": {
    "small": 1366,
    "normal": 1500
  }
};

},{}]},{},[1]);
